<template>
  <div class="page-class page-center">
    <div class="page-staff-form">
      <h1 class="page-title">{{ title }}</h1>
      <banner-form-box list-url="/productBanner" :id="id" :form-type="formType" @add="onAdd" @edit="onEdit" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import router from '@/router';
import BannerFormBox from '../../components/BannerFormBox.vue';

export default {
  name: 'BannerForm',
  components: {
    BannerFormBox
  },
  async mounted() {
    if (this.$route.name === 'Product-Banner-new') {
      this.formType = 'new';
      this.id = null;
    } else {
      this.formType = 'update';
      this.id = this.$route.params.id;
      await this.getOne({
        id: this.id,
        router
      });
    }
  },
  data() {
    return {
      formType: '',
      id: null
    };
  },
  computed: {
    title() {
      return this.formType === 'new' ? 'Add new Banner' : 'Update Banner';
    },
    ...mapState('catalogueBanner', ['loading', 'baseUrl', 'banner']),
    ...mapState('auth', ['user'])
  },
  methods: {
    ...mapActions('catalogueBanner', ['getOne', 'postOne', 'patchOne']),
    onAdd({ productBanner }) {
      this.postOne({
        productBanner,
        router,
        redirectUrl: '/productBanner'
      });
    },
    onEdit({ productBanner }) {
      this.patchOne({
        id: this.id,
        productBanner,
        router,
        redirectUrl: '/productBanner'
      });
    }
  }
};
</script>
